<template>
  <UserIndex
      :create-text="'New Guardian'"
      :can-create="
      !!$store.getters['entities/user-permissions/find']('create guardians')
    "
      :can-delete="
      !!$store.getters['entities/user-permissions/find']('delete guardians')
    "
      :default-role="'guardian'"
      :default-role-filter="'guardian'"
      :show-roles="false"
      :roles-filter="true"
      :roles_includes="'guardian'"

      :type="'Guardian'"
      :route="'guardians'"
      :tabs="[{ title: `Guardian List` }]"
      :tabEntity="'Guardian'"
      :header-text="'Guardians'"
  ></UserIndex>
</template>
<script>
import UserIndex from "@/components/users/UserIndex";

export default {
  name: "Guardians.index",
  components: { UserIndex },
};
</script>